import moment from 'moment-timezone'
import { ExportToCsv } from 'export-to-csv'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { useRoute } from 'vue-router'

const isInt = (n) => {
    return Number(n) === n && n % 1 === 0;
}

const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
}

export const currencyFormatter = (currency, locale) => {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        // minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
}

// get currency locale
export const getCurrencyLocale = currency => {
    // console.log('currency', currency)
    const currencyLocale = currency.toLowerCase()
    switch (currencyLocale) {
        case 'usd':
            return 'en-US'
        case 'eur':
            return 'de-DE'
        case 'gbp':
            return 'en-GB'
        case 'jpy':
            return 'ja-JP'
        case 'cny':
            return 'zh-CN'
        case 'krw':
            return 'ko-KR'
        case 'inr':
            return 'en-IN'
        case 'idr':
            return 'id-ID'
        case 'myr':
            return 'en-MY'
        case 'php':
            return 'en-PH'
        case 'twd':
            return 'zh-TW'
        case 'thb':
            return 'th-TH'
        case 'vnd':
            return 'vi-VN'
        case 'aud':
            return 'en-AU'
        case 'brl':
            return 'pt-BR'
        case 'ngn':
            return 'en-NG'
        case 'ghs':
            return 'en-GH'
        case 'zar':
            return 'en-ZA'
        case 'kes':
            return 'sw-KE'
        case 'nad':
            return 'en-NA'
        case 'egp':
            return 'ar-EG'
        case 'cfa':
            return 'dje-NE'

        default:
            return 'en-US'
    }
}
export const formatAmount = (price, currency = 'NGN', locale = 'en-NG') =>
    currencyFormatter(currency, getCurrencyLocale(currency)).format(price || 0)

// export const quantityFormatter = (currency, locale) => {
//   return new Intl.NumberFormat(locale, {
//     style: '',
//     currency,
//     minimumFractionDigits: 2,
//   })
// }

// export const formatQuantity = (price, currency = 'NGN', locale = 'en-NG') => quantityFormatter(currency, locale).format(price || 0)
export const formatQuantity = (quantity) => {
    return isFloat(quantity) ? (quantity || 0).toFixed(2) : quantity || 0
}

export const formatDate = (date, format = 'DD/MM/YYYY') => {
    if (date) return moment(date).format(format)
    return '-'
}

export const formatDateInLocal = date => {
    if (date) return moment(date).format('Do MMM YYYY')
    return '-'
}

export const formatDateInLocalWithoutYear = date => {
    if (date) return moment(date).format('MMM')
    return '-'
}

export const formatDateMonthInLocal = date => {
    if (date) return moment(date).format('Do MMM')
    return '-'
}

export const getFullYear = date => {
    if (date) return moment(date).format('YYYY')
    return '-'
}
// converts currency symbol to currency string
export const getCurrencyName = currency => {
    switch (currency) {
        case 'NGN':
            return 'Naira'
        case 'USD':
            return 'Dollar'
        case 'EUR':
            return 'Euro'
        case 'GBP':
            return 'Pound'
        case 'JPY':
            return 'Yen'
        case 'CNY':
            return 'Renminbi'
        case 'KRW':
            return 'Won'
        case 'INR':
            return 'Rupee'
        case 'IDR':
            return 'Rupiah'
        case 'MYR':
            return 'Ringgit'
        case 'PHP':
            return 'Peso'
        case 'TWD':
            return 'Taiwan Dollar'
        case 'THB':
            return 'Baht'
        case 'VND':
            return 'Dong'
        case 'AUD':
            return 'Australian Dollar'
        case 'BRL':
            return 'Real'
        case 'CAD':
            return 'Canadian Dollar'
        case 'CHF':
            return 'Swiss Franc'
        case 'CZK':
            return 'Czech Koruna'
        case 'DKK':
            return 'Danish Krone'
        case 'HKD':
            return 'Hong Kong Dollar'
        case 'GHS':
            return 'Ghana Cedis'
        case 'ZAR':
            return 'SA Rand'
        case 'KES':
            return 'Kenya KE'
        case 'NAD':
            return 'Namibia NAD'
        case 'EGP':
            return 'Egypt EGP'
        case 'CFA':
            return 'CFA'

        default:
            return 'Naira'
    }
}

export const setRolesBGAndTextColor = roles => {
    let item
    if (Array.isArray(roles)) {
        item = roles?.[0]
    } else {
        item = roles
    }

    switch (item) {
        case 'customer':
            return 'background-color: #edf0fd; color: #132c8c;'

        case 'vendor':
            return 'background-color: #ce541b; color: white;'

        case 'debtor':
            return 'background-color: #14c773; color: white;'

        case 'investor':
            return 'background-color: #132c8c; color: white;'

        case 'creditor':
            return 'background-color: #eb2d36; color: white;'

        case 'supplier':
            return 'background-color: #662deb; color: white;'

        default:
            return 'background-color: #662deb; color: white;'
    }
}

//calculate the days from today
export const getDaysFromToday = date => {
    const today = moment().format('YYYY-MM-DD')
    const days = moment(date).diff(moment(today), 'days')
    return days
}
// export const paginationOptions = (payload) => `limit=${payload?.limit || 25}&page=${payload?.page || 1}`

export const paginationOptions = payload => {
    // console.log('paginationOptions: ', payload)
    // const route = useRoute()

    if (payload?.filterType && payload?.filterType === 'customrange') {
        return `filterType=${payload?.filterType || 'customrange'}&filterByBusiness=${payload?.filterByBusiness || ''}&filterByType=${payload?.filterByType || ''}&filterByChannel=${payload?.filterByChannel || ''}&filterByStaff=${payload?.filterByStaff || ''}&filterByPaymentMethod=${payload?.filterByPaymentMethod || ''}&filterBySubtype=${payload?.filterBySubtype || ''}&filterByCategory=${payload?.filterByCategory || ''}&filterByBrand=${payload?.filterByBrand || ''}&filterByQuantity=${payload?.filterByQuantity || ''}&filterByCost=${payload?.filterByCost || ''}&filterByTags=${payload?.filterByTags || ''}&startDate=${payload?.start ||
            payload?.startDate}&endDate=${payload?.end || payload?.endDate}&limit=${payload?.limit || 25}&page=${payload?.page ||
            1}&sort=${payload?.sort || 'date'}&mode=${payload?.mode || 'desc'}`
    }

    if (payload?.filterType && payload?.filterType === 'fixed') {
        return `filterType=${payload?.filterType || 'fixed'}&filterByBusiness=${payload?.filterByBusiness || ''}&filterByType=${payload?.filterByType || ''}&filterByChannel=${payload?.filterByChannel || ''}&filterByStaff=${payload?.filterByStaff || ''}&filterByPaymentMethod=${payload?.filterByPaymentMethod || ''}&filterBySubtype=${payload?.filterBySubtype || ''}&filterByCategory=${payload?.filterByCategory || ''}&filterByBrand=${payload?.filterByBrand || ''}&filterByQuantity=${payload?.filterByQuantity || ''}&filterByCost=${payload?.filterByCost || ''}&filterByTags=${payload?.filterByTags || ''}&startDate=${payload?.start || payload?.startDate}&endDate=${payload?.end || payload?.endDate}&howLongInNumber=${payload?.howLongInNumber ||
            30}&oneOfDaysWeeksMonthsYears=${payload?.oneOfDaysWeeksMonthsYears || 'days'}&limit=${payload?.limit ||
            25}&page=${payload?.page || 1}&sort=${payload?.sort || 'date'}&mode=${payload?.mode || 'desc'}`
    }

    if (payload?.filterType && payload?.filterType === 'search') {
        return `filterType=${payload?.filterType || 'fixed'}&filterByBusiness=${payload?.filterByBusiness || ''}&filterByType=${payload?.filterByType || ''}&filterByChannel=${payload?.filterByChannel || ''}&filterByStaff=${payload?.filterByStaff || ''}&filterByPaymentMethod=${payload?.filterByPaymentMethod || ''}&filterBySubtype=${payload?.filterBySubtype || ''}&filterByCategory=${payload?.filterByCategory || ''}&filterByBrand=${payload?.filterByBrand || ''}&filterByQuantity=${payload?.filterByQuantity || ''}&filterByCost=${payload?.filterByCost || ''}&filterByTags=${payload?.filterByTags || ''}&startDate=${payload?.start || payload?.startDate}&endDate=${payload?.end || payload?.endDate}&limit=${payload?.limit || 25}&page=${payload?.page || 1}&sort=${payload?.sort || 'date'}&mode=${payload?.mode || 'desc'}&q=${payload?.q || ''}`
    }

    // if (payload?.limit && payload.page && route?.path?.startsWith('/admin')) {
    //     return `limit=${payload?.limit}&page=${payload?.page}`
    // }

    if (payload?.filterType && payload?.filterType === 'adminRange') {
        if (payload?.q) {
            return `filterType=${'adminRange'}&limit=${payload?.limit}&page=${payload?.page}&start=${payload?.start || payload?.startDate ||
                '2000-01-01'}&end=${payload?.end || payload?.endDate || '2023-01-10'}&q=${payload?.q}`
        }
        return `filterType=${'adminRange'}&limit=${payload?.limit}&page=${payload?.page}&start=${payload?.start || payload?.startDate ||
            '2000-01-01'}&end=${payload?.end || payload?.endDate || '2023-01-10'}`
    }

    // return `limit=${payload?.limit || 25}&page=${payload?.page || 1}`
    return `filterType=fixed&howLongInNumber=0&oneOfDaysWeeksMonthsYears=days&limit=${payload?.limit ||
        25}&page=${payload?.page || 1}&sort=${payload?.sort || 'date'}&mode=${payload?.mode || 'desc'}`
}

const allWeek = [
    {
        week: 'Week 1',
        value: 1
    },
    {
        week: 'Week 2',
        value: 2
    },
    {
        week: 'Week 3',
        value: 3
    },
    {
        week: 'Week 4',
        value: 4
    },
    {
        week: 'Week 5',
        value: 5
    },
    {
        week: 'Week 6',
        value: 6
    },
    {
        week: 'Week 7',
        value: 7
    },
    {
        week: 'Week 8',
        value: 8
    },
    {
        week: 'Week 9',
        value: 9
    },
    {
        week: 'Week 10',
        value: 10
    },
    {
        week: 'Week 11',
        value: 11
    },
    {
        week: 'Week 12',
        value: 12
    },
    {
        week: 'Week 13',
        value: 13
    },
    {
        week: 'Week 14',
        value: 14
    },
    {
        week: 'Week 15',
        value: 15
    },
    {
        week: 'Week 16',
        value: 16
    },
    {
        week: 'Week 17',
        value: 17
    },
    {
        week: 'Week 18',
        value: 18
    },
    {
        week: 'Week 19',
        value: 19
    },
    {
        week: 'Week 20',
        value: 20
    },
    {
        week: 'Week 21',
        value: 21
    },
    {
        week: 'Week 22',
        value: 22
    },
    {
        week: 'Week 23',
        value: 23
    },
    {
        week: 'Week 24',
        value: 24
    },
    {
        week: 'Week 25',
        value: 25
    },
    {
        week: 'Week 26',
        value: 26
    },
    {
        week: 'Week 27',
        value: 27
    },
    {
        week: 'Week 28',
        value: 28
    },
    {
        week: 'Week 29',
        value: 29
    },
    {
        week: 'Week 30',
        value: 30
    },
    {
        week: 'Week 31',
        value: 31
    },
    {
        week: 'Week 32',
        value: 32
    },
    {
        week: 'Week 33',
        value: 33
    },
    {
        week: 'Week 34',
        value: 34
    },
    {
        week: 'Week 35',
        value: 35
    },
    {
        week: 'Week 36',
        value: 36
    },
    {
        week: 'Week 37',
        value: 37
    },
    {
        week: 'Week 38',
        value: 38
    },
    {
        week: 'Week 39',
        value: 39
    },
    {
        week: 'Week 40',
        value: 40
    },
    {
        week: 'Week 41',
        value: 41
    },
    {
        week: 'Week 42',
        value: 42
    },
    {
        week: 'Week 43',
        value: 43
    },
    {
        week: 'Week 44',
        value: 44
    },
    {
        week: 'Week 45',
        value: 45
    },
    {
        week: 'Week 46',
        value: 46
    },
    {
        week: 'Week 47',
        value: 47
    },
    {
        week: 'Week 48',
        value: 48
    },
    {
        week: 'Week 49',
        value: 49
    },
    {
        week: 'Week 50',
        value: 50
    },
    {
        week: 'Week 51',
        value: 51
    },
    {
        week: 'Week 52',
        value: 52
    }
]
export const transactionTypes = {
    sales: 'Sales',
    purchases: 'Purchases',
    expenses: 'Expenses',
    assets_purchase: 'Assets Acquisitions',
    inflows: 'Money In',
    outflows: 'Money Out',
    assets_sale: 'Assets Sales'
}

export const expenseSidebarOptions = {
    utility: 'Utility',
    assets: 'Assets Acquisitions',
    transport: 'Transportation',
    commission: 'Commission',
    rent: 'Rent',
    others: 'Others Expenses',
    Others: 'Others Expenses',
    ads: 'Advertisement',
    fees: 'Fees',
    interest_expense: 'Interest Expense',
    interest_income: 'Interest Income',
    other_income: 'Other Income',
    other_expense: 'Other Expense',
    packaging: 'Packaging',
    discount: 'Discount Given',
    home_office_cost: 'Home Office Cost',
    office_supplies: 'Office Supplies',
    salary: 'Salary/Payroll',
    bank: 'Bank Fees/Charges',
    business_insurance: 'Business Insurance',
    business_meals_travel_expense: 'Business Meals & Travel Expense',
    furniture_equipment_machinery: 'Furniture/Equipment/Machinery',
    input_raw_materials: 'Input/Raw materials',
    license_registration: 'License & Registration',
    professional_business_fees: 'Professional & Business Fees',
    trainings: 'Trainings',
    website_software: 'Website/Software',
    advertising_marketing: 'Advertising & Marketing',
    entertainment: 'Entertainment',
    vehicle_expenses: 'Vehicle Expenses',
    taxes: 'Taxes',
    business_insurance: 'Business Insurance',
    electricity: 'Electricity',
    telephone: 'Telephone',
    csr: 'CSR',
    maintenance_repairs: 'Maintenance/Repairs',
    employee_benefits: 'Employee Benefits',
    cost_of_goods_sold: 'Cost of Goods Sold',
}

export const subtypes = {
    transport: 'Transport',
    commission: 'Commission',
    raw_materials: 'Raw Materials',
    ads: 'Advertisement',
    packaging: 'Packaging',
    discount: 'Discount Given',
    rent: 'Rent',
    utility: 'Utility',
    interest_expense: 'Interest Expense',
    others: 'Others',
    home_office_cost: 'Home Office Cost'

    // { value: 'others', name: 'Other Expenses' },
    // { value: 'home_office_cost', name: 'Home Office Cost' },
    // { value: 'furniture_equipment_machinery', name: 'Furniture/Equipment/Machinery' },
    // { value: 'office_supplies', name: 'Office Supplies' },
    // { value: 'advertising_marketing', name: 'Advertising & Marketing' },
    // { value: 'website_software', name: 'Website/Software' },
    // { value: 'entertainment', name: 'Entertainment' },
    // { value: 'business_meals_travel_expense', name: 'Business Meals & Travel Expense' },
    // { value: 'salary', name: 'Salary/Payroll' },
    // { value: 'vehicle_expenses', name: 'Vehicle Expenses' },
    // { value: 'taxes', name: 'Taxes' },
    // { value: 'business_insurance', name: 'Business Insurance' },
    // { value: 'license_registration', name: 'License & Registration' },
    // { value: 'professional_business_fees', name: 'Professional & Business Fees' },
    // { value: 'trainings', name: 'Trainings' },
    // { value: 'input_raw_materials', name: 'Input/Raw materials' },
    // { value: 'bank', name: 'Bank Fees/Charges' },
}

export const formatDateFilter = date => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
}

export const downloadCSV = (file, title) => {
    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: title,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    }

    const csvExporter = new ExportToCsv(options)
    csvExporter.generateCsv(file)
}

export const glAccountTypes = {
    0: 'Cash',
    1: 'Credit',
    2: 'Debit'
}

export const nameTypes = {
    sales: 'Customer',
    purchases: 'Supplier',
    expenses: 'Vendor',
    capital: 'Investor',
    borrowings: 'Lender',
    loans_given: 'Debtor'
}

// format number to currency
export const formatNumber = number => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    }).format(number)
}

// format number to thousands with comma
export const formatNumberWithComma = number => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    }).format(number)
}

const pdf = new jsPDF('p', 'mm', 'a4')

export const handleDownloadPDF = async (value, invoiceNumber) => {
    try {
        // let canvas
        let pdfValue = null
        let canvaData = null

        if (typeof value !== 'string') {
            canvaData = await html2canvas(value, { scale: 1 })

            value = canvaData.toDataURL('image/jpeg', 1)
            pdfValue = value
        }

        const pageWidth = pdf.internal.pageSize.getWidth()
        const pageHeight = pdf.internal.pageSize.getHeight()
        const imageWidth = canvaData?.width
        const imageHeight = canvaData?.height

        const ratio = imageWidth / imageHeight >= pageWidth / pageHeight ? pageWidth / imageWidth : pageHeight / imageHeight
        pdf.addImage(pdfValue, 'JPEG', 0, 0, imageWidth * ratio, imageHeight * ratio)
        pdf.save(`InvoiceDetails-${invoiceNumber}.pdf`)
    } catch (err) {
        console.error(err.message)
    }
}

// converts html to buffer

export async function htmlToBuffer(value) {
    let pdfValue = null
    let canvaData = null

    if (typeof value !== 'string') {
        canvaData = await html2canvas(value, { scale: 1 })

        value = canvaData.toDataURL('image/jpeg', 1)
        pdfValue = value
    }

    const pageWidth = pdf.internal.pageSize.getWidth()
    const pageHeight = pdf.internal.pageSize.getHeight()
    const imageWidth = canvaData?.width
    const imageHeight = canvaData?.height

    const ratio = imageWidth / imageHeight >= pageWidth / pageHeight ? pageWidth / imageWidth : pageHeight / imageHeight
    pdf.addImage(pdfValue, 'JPEG', 0, 0, imageWidth * ratio, imageHeight * ratio)
    const blob = pdf.output('blob')
    const file = new File([blob], 'invoice.pdf', { type: 'application/pdf' })

    // console.log('file', file)
    return file
}

export const getThisMonthDateRange = () => {
    const date = new Date()
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1).toISOString()
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString()
    return { startDate, endDate }
}

export const getLastMonthDateRange = () => {
    const date = new Date()
    const startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString()
    const endDate = new Date(date.getFullYear(), date.getMonth(), 0).toISOString()
    return { startDate, endDate }
}

export const getLastSevenDaysDateRange = (days = 7) => {
    const date = new Date()
    let startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - days).toISOString()
    let endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString()
    startDate = new Date(formatDate(startDate, 'YYYY-MM-DD') + ' 00:00:00').toISOString()
    endDate = new Date(formatDate(endDate, 'YYYY-MM-DD') + ' 23:59:59').toISOString()
    return { startDate, endDate }
}

// paginate data
export const paginate2 = (data, pageNumber, pageSize) => {
    const startIndex = (pageNumber - 1) * pageSize
    return data.slice(startIndex, startIndex + pageSize)
}

// get total pages
export const getTotalPages = (data, pageSize) => {
    return Math.ceil(data.length / pageSize)
}

export const paginate = (data, pageNumber, pageSize) => {
    const page = parseInt(pageNumber, 10) || 1 // defaults to 1 if no page query specified
    const offset = (page - 1) * pageSize

    // do your mapping (do this in your renderMapping() method)
    return data.slice(0 + offset, page + offset)
}

export const getEntityInitials = str => {
    const entity = str?.split(' ')
    let initials = ''

    if (entity?.length > 1) {
        const first = entity?.[0]?.split('')[0]
        const second = entity?.[1]?.split('')[0]
        initials = `${first} ${second}`
    } else {
        const first = entity?.[0]?.split('')[0]
        const second = entity?.[0]?.split('')[1]
        initials = `${first || ''} ${second || ''}`
    }

    return initials.toUpperCase()
}

export const titleCase = str => {
    if (!str) return ''
    return str
        .toLowerCase()
        .split(' ')
        .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1)
        })
        .join(' ')
}

export const businessPlans = {
    free: '1',
    small: '2',
    medium: '3',
    basic: '4',
    store: '5',
    professional: '6',
    enterprise: '7',
}

export const defaultUsers = {
    '1': 1,
    '2': 3,
    '3': 10,
    '4': 1,
    '5': 4,
    '6': 4,
    '7': 11,
}

export const defaultBusinesses = {
    '1': 1,
    '2': 1,
    '3': 3,
    '4': 1,
    '5': 3,
    '6': 3,
    '7': 3,
}

export const plansAdditionaUserlCost = {
    '1': 0,
    '2': 1000,
    '3': 2500,
    '4': 2000,
    '5': 5000,
    '6': 5000,
    '7': 5000,
}

export const plansAdditionaUserlCostDollar = {
    '1': 0,
    '2': 1000,
    '3': 2500,
    '4': 0,
    '5': 5,
    '6': 5,
    '7': 5,
}

export const plansAdditionalBusinessCost = {
    '1': 0,
    '2': 4000,
    '3': 50000,
    '4': 4000,
    '5': 10000,
    '6': 10000,
    '7': 20000,
}

export const plansAdditionalBusinessCostDollar = {
    '1': 0,
    '2': 400,
    '3': 500,
    '4': 0,
    '5': 100,
    '6': 100,
    '7': 200,
}
export const plansCost = {
    '1': 0,
    '2': 5000,
    '3': 50000,
    '4': 5000,
    '5': 50000,
    '6': 50000,
    '7': 100000,
}
export const plansCostDollar = {
    '1': 0,
    '2': 10,
    '3': 100,
    '4': 10,
    '5': 100,
    '6': 100,
    '7': 200,
}
export const plansName = {
    '1': 'Micro Business Plan',
    '2': 'Small Business Plan',
    '3': 'Medium Enterprise Plan',
    '4': 'Basic Plan',
    '5': 'Store Plan',
    '6': 'Professional Plan',
    '7': 'Enterprise Plan',
}
export const periodMultiplier = {
    'monthly': 1,
    'yearly': 10,
}

export const getDaysDate = (numberOfDays, startDate) => {
    let date = new Date()
    if (startDate) date = new Date(startDate)
    const dDay = date
    const dates = []
    for (let i = 0; i < numberOfDays; i++) {
        // console.log(date.getMonth(), i)
        const iDay = dDay.setMonth(date.getMonth() - i)
        const dateAndMonth = formatDateInLocalWithoutYear(iDay)
        dates.push(dateAndMonth)
    }
    return dates
}

export const getDifferenceInDatesInDays = (startDate, endDate) => {
    const date1 = new Date(startDate)
    const date2 = new Date(endDate)
    const diffTime = Math.abs(date2 - date1)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays > 0 ? diffDays : 0
}

export const getDifferenceInDates = (startDate, endDate) => {
    startDate = new Date(startDate)
    endDate = new Date(endDate)
    var months
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12
    months -= startDate.getMonth()
    months += endDate.getMonth()
    return months <= 0 ? 0 : months
}

export const colorSelector = {
    1: 'green',
    2: '#FFC000',
    7: 'blue'
}

export const sortAlphaNumericData = ({ data, value, order }) => {
    // console.log(data, value, order)
    if (order === 'asc') {
        const sorted = data?.sort((a, b) => {
            return a[value]?.localeCompare(b[value], undefined, {
                numeric: true,
                sensitivity: 'base'
            })
        })
        return sorted
    } else if (order === 'desc') {
        const sorted = data.sort((a, b) => {
            return b[value]?.localeCompare(a[value], undefined, {
                numeric: true,
                sensitivity: 'base'
            })
        })
        return sorted
    } else {
        return data
    }
}

export const sortByNumericData = ({ data, value, order }) => {
    if (order === 'asc') {
        const sorted = data.sort((a, b) => a[value] - b[value])
        return sorted
    }
    if (order === 'desc') {
        const sorted = data.sort((a, b) => b[value] - a[value])
        return sorted
    } else {
        return data
    }
}

export const sortByDate = ({ data, value, order }) => {
    if (order === 'asc') {
        const sorted = data.sort((a, b) => new Date(a[value]).getTime() - new Date(b[value]).getTime())
        return sorted
    }
    if (order === 'desc') {
        const sorted = data.sort((a, b) => new Date(b[value]).getTime() - new Date(a[value]).getTime())
        return sorted
    } else {
        return data
    }
}

export const getNewSku = (lastSku) => {
    const skuInfo = lastSku.split('-')
    let newSku = `${skuInfo[0]}-001`
    const skuNumber = skuInfo[1] ? parseInt(skuInfo[1]) + 1 : 1
    if (skuNumber < 10) {
        newSku = `${skuInfo[0]}-00${skuNumber}`
    } else if (skuNumber < 100) {
        newSku = `${skuInfo[0]}-0${skuNumber}`
    } else {
        newSku = `${skuInfo[0]}-${skuNumber}`
    }
    return newSku
}

export const calculateTotalMoneySaved = (data) => {
    let totalMoneySaved = 0
    const mainDisacount = parseFloat(data?.discount || 0)
    if (mainDisacount) {
        if (data.discountType === 'percent' || data.discountType === 'percentage') {
            totalMoneySaved = data.subtotal * (mainDisacount / 100)
        } else {
            totalMoneySaved = mainDisacount
        }
    } else {
        totalMoneySaved = data?.items.reduce((acc, item) => {
            if (item.discount !== '') {
                return acc + ((item?.oldPrice|| 0) - (item?.price || 0))
            }
            return acc
        }, 0)
    }

    return totalMoneySaved;
}

export const getMonday = (d) => {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export const ALL_PERMISSIONS = [
    { info: 'Show Only Business Records', value: 99 },
    { info: 'Show All Business Records', value: 100 },

    { info: 'Can View Transactions', value: 51 },
    { info: 'Can View All Transactions', value: 1 },
    { info: 'Can View Sales Transactions', value: 2 },
    { info: 'Can View Purchase Transactions', value: 3 },
    { info: 'Can View Expense Transactions', value: 4 },
    { info: 'Can View Others Transactions', value: 42 },
    { info: 'Can View Inventory', value: 50 },
    { info: 'Can View Inventory Summary', value: 5 },
    { info: 'Can View Inventory Products', value: 6 },
    { info: 'Can View Inventory Analysis', value: 7 },
    { info: 'Can View Inventory Product Details', value: 8 },
    { info: 'Can View Services', value: 9 },

    { info: 'Can View Debtors', value: 10 },
    { info: 'Can View Creditors', value: 11 },
    { info: 'Can View Entity', value: 13 },
    { info: 'Can View Customer Details', value: 14 },
    { info: 'Can View Pos', value: 141 },
    { info: 'Can View Production', value: 142 },

    { info: 'Can View Reports', value: 15 },
    { info: 'Can View Sales Reports', value: 16 },
    { info: 'Can View Expense Reports', value: 17 },
    { info: 'Can View Inventory Reports', value: 18 },

    { info: 'Can View Profit and Loss Reports', value: 19 },
    { info: 'Can View Balance Sheet Reports', value: 20 },
    { info: 'Can View Invoices Reports', value: 21 },

    { info: 'Can Create Transaction', value: 22 },
    { info: 'Can Create Sales Transaction', value: 23 },
    { info: 'Can Create Expense Transaction', value: 24 },
    { info: 'Can Create Purchase Transaction', value: 25 },

    { info: 'Can Create Outflow Transaction', value: 26 },
    { info: 'Can Create Inflow Transaction', value: 27 },
    { info: 'Can Create Credit Sales Transaction', value: 28 },
    { info: 'Can Create Credit Purchase Transaction', value: 29 },
    { info: 'Can Create Customer', value: 30 },
    { info: 'Can Create Invoice', value: 31 },
    { info: 'Can Create Product', value: 32 },
    { info: 'Can Create Service', value: 33 },
    { info: 'Can Create Product', value: 34 },
    { info: 'Can Edit Entity', value: 35 },
    { info: 'Can Edit Product', value: 36 },
    { info: 'Can Edit Prices', value: 37 },

    { info: 'Can Delete Transaction', value: 38 },
    { info: 'Can Delete Entity', value: 39 },
    { info: 'Can Delete Product', value: 40 },
    { info: 'Can Delete Invoices', value: 41 }
]

export const NOT_PERMITTED = [
    // {info: "Show Only Business Records", value: 99},
    // {info: "Show All Business Records", value: 100},

    { info: 'Can Not View Transactions', value: 51 },
    { info: 'Can Not View All Transactions', value: 1 },
    { info: 'Can Not View Sales Transactions', value: 2 },
    { info: 'Can Not View Purchase Transactions', value: 3 },
    { info: 'Can Not View Expense Transactions', value: 4 },
    { info: 'Can Not View Others Transactions', value: 42 },
    { info: 'Can Not View Inventory', value: 50 },
    { info: 'Can Not View Inventory Summary', value: 5 },
    { info: 'Can Not View Inventory Products', value: 6 },
    { info: 'Can Not View Inventory Analysis', value: 7 },
    { info: 'Can Not View Inventory Product Details', value: 8 },
    { info: 'Can Not View Services', value: 9 },

    { info: 'Can Not View Debtors', value: 10 },
    { info: 'Can Not View Creditors', value: 11 },
    { info: 'Can Not View Entity', value: 13 },
    { info: 'Can Not View Customer Details', value: 14 },
    { info: 'Can Not View Pos', value: 141 },
    { info: 'Can Not View Production', value: 142 },

    { info: 'Can Not View Reports', value: 15 },
    { info: 'Can Not View Sales Reports', value: 16 },
    { info: 'Can Not View Expense Reports', value: 17 },
    { info: 'Can Not View Inventory Reports', value: 18 },

    { info: 'Can Not View Profit and Loss Reports', value: 19 },
    { info: 'Can Not View Balance Sheet Reports', value: 20 },
    { info: 'Can Not View Invoices Reports', value: 21 },

    { info: 'Can Not Create Transaction', value: 22 },
    { info: 'Can Not Create Sales Transaction', value: 23 },
    { info: 'Can Not Create Expense Transaction', value: 24 },
    { info: 'Can Not Create Purchase Transaction', value: 25 },

    { info: 'Can Not Create Outflow Transaction', value: 26 },
    { info: 'Can Not Create Inflow Transaction', value: 27 },
    { info: 'Can Not Create Credit Sales Transaction', value: 28 },
    { info: 'Can Not Create Credit Purchase Transaction', value: 29 },
    { info: 'Can Not Create Customer', value: 30 },
    { info: 'Can Not Create Invoice', value: 31 },
    { info: 'Can Not Create Product', value: 32 },
    { info: 'Can Not Create Service', value: 33 },
    { info: 'Can Not Create Product', value: 34 },
    { info: 'Can Not Edit Entity', value: 35 },
    { info: 'Can Not Edit Product', value: 36 },
    { info: 'Can Not Edit Prices', value: 37 },

    { info: 'Can Not Delete Transaction', value: 38 },
    { info: 'Can Not Delete Entity', value: 39 },
    { info: 'Can Not Delete Product', value: 40 },
    { info: 'Can Not Delete Invoices', value: 41 }
]
